import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";

const PremiumLoanPlan = () => {
  // const userLoginData = useSelector((state:any) => state.data.credit);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // const creditEligibility = useSelector((state: any) => state.data.loanCreditEligibility.value);
  const [open, setOpen] = useState(false);
  const onOpenModal = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const handleSubmit = () => {
    // navigate("/loans-premium-eligibility");
    navigate("premium-loan-package")
  };

  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className="lg:mt-3 w-full lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          <div className="lg:mt-3 pt-2 bg-[#E1F9EC] rounded-[10px]">
            <div className="flex justify-between">
              <img src="/images/bro.png" className="lg:block hidden" alt="hero" />

              <div className="flex flex-col text-center">
                <h4 className="text-[#00B07B] text-[32px] font-[700] max-w-[561px] ">
                  Get admission to get loan
                </h4>
                <h6 className="text-[16px] font-[500] text-[#5A5A5A]">
                  Please ensure that you verify your school, country, and
                  eligibility for admission.
                </h6>
              </div>
              <img src="/images/bro1.png" className="lg:block hidden" alt="hero" />
            </div>
          </div>



          <div className="md:flex justify-center w-full pt-10">
            <div className="flex justify-center bg-white py-10 px-7">
              <div className=" text-center ">
                <h2 className="text-[#00B07B] text-[24px] font-[700] ">
                  EDUPREMIUM
                </h2>
                <h5 className="text-[#65656C] text-[14px] max-w-[443px] font-[400]">
                  This is intended for students who do not have admission but
                  they are eligible to qualify for a loan
                </h5>
                <div>
                  <h5 className="text-[#6F6C90] pt-5 text-[20px] font-[400] max-w-[394px]">
                    Student without admission but have the documents to gain
                    admission
                  </h5>
                </div>
                {/* <h4 className="text-[#170F49] text-[32px] font-bold pt-1">
                  $450
                </h4> */}

                <div className="mt-5">

                  <button

                    onClick={onOpenModal}
                    className="py-[10px] px-[70px] bg-[#00B07B] text-[16px] font-normal text-white rounded-[30px] border border-[#ff] "
                  >
                    Get Started
                  </button>

                </div>
              </div>
            </div>

            <div className="bg-[#00B07B]/[5%] pl-4 pr-10 flex justify-center text-center items-center">
              <div className="">
                <div className="flex justify-center text-center">
                  <h5 className="text-[#170F49] text-[18px] font-semibold">
                    Status
                  </h5>
                </div>
                <div className="flex items-center pt-3 gap-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                  <h5 className="text-[#170F49] text-[18px]">
                    Get everything in EduGold
                  </h5>
                </div>
                <div className="flex items-center pt-3 gap-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                  <h5 className="text-[#170F49] text-[18px]">
                    Gain Admission
                  </h5>
                </div>
                <div className="flex items-center pt-3 gap-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                  <h5 className="text-[#170F49] text-[18px]">Get Loan </h5>
                </div>
              </div>
            </div>
          </div>

         


        </div>
      </div>

      <Modal open={open} onClose={onCloseModal} center>
        <div className=" flex justify-center">
          <h4 className="tex-[#000000] md:text-[18px] text-[16px] text-center font-bold">
            AfriPay Non-Refundable Policy for Loan Applications
          </h4>
        </div>
        <div className="md:w-[600px] body-font font-poppins">
          <div className="flex justify-center mt-9">
            {/* <h4 className="text-center text-[14px]">
                 My afripay wallet balance
               </h4> */}
          </div>
          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Introduction</h4>
            <h4 className=" text-[14px]">
              This policy outlines the non-refundable terms for loan application
              fees associated with AfriPay’s educational loan products:
              EduSilver, EduGold, and EduPremium. By applying for any of these
              loans, you agree to the terms and conditions stated in this
              policy.
            </h4>
          </div>
          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Loan Products Covered</h4>
            <h4 className=" text-[14px]">
              This policy applies to the following :
              <br />- EduSilver: This product checks your school, country, and
              admission eligibility. The application for EduSilver is free of
              charge.
            </h4>
            <h4 className=" text-[14px] mt-2">
              - EduGold: This product includes all the benefits of EduSilver,
              checks your credit history. If your credit score is good, then you
              can proceed to apply for the loan. The application fee for EduGold
              is $4, which is non-refundable.
            </h4>
            <h4 className=" text-[14px] mt-2">
              - EduPremium: This product is intended for students who do not yet
              have admission but have the necessary documents to secure
              admission and a loan. The application fee for EduPremium is $450,
              which is non-refundable.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Application Fee</h4>
            <h4 className=" text-[14px]">
              Each loan application is subject to a non-refundable application
              fee. The application fee covers the cost of processing your loan
              application and is required to submit your application for review.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Non-Refundable Terms</h4>
            <h4 className=" text-[14px]">
              The application fee is strictly non-refundable
            </h4>

          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">
              Acknowledgment and Acceptance
            </h4>
            <h4 className=" text-[14px]">
              By submitting a loan application, applicants acknowledge and
              accept that the application fee is non-refundable. It is the
              responsibility of the applicant to ensure that they meet all
              eligibility criteria and provide accurate information before
              submitting their application.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Contact Information</h4>
            <h4 className=" text-[14px]">
              For any questions or concerns regarding this non-refundable
              policy, applicants can contact AfriPay customer service at <span className='text-[#209D5A]'>hello@goafripay.com</span>.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Changes to This Policy</h4>
            <h4 className=" text-[14px]">
              AfriPay reserves the right to modify or update this policy at any
              time. Any changes will be effective immediately upon posting on
              our official website. Applicants are encouraged to review the
              policy regularly for any updates.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Governing Law</h4>
            <h4 className=" text-[14px]">
              This policy is governed by the laws of Federal Republic of
              Nigeria. Any disputes arising from or related to this policy shall
              be subject to the exclusive jurisdiction of the courts of Federal
              Republic of Nigeria
            </h4>
          </div>

          <div className="flex items-start mt-5 ">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="w-5 h-5 accent-green-600 bg-[#D9D9D9] border-green-600 rounded"
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-[15px]  text-gray-900 "
            >
              I have read this and I understand
            </label>
          </div>
          <div className="flex justify-center  pt-2">
            <NavLink to="/premium-loan-package">
            <button
              // onClick={handleSubmit}
              
              disabled={!isChecked}
              className={`text-white flex justify-center items-center py-3 px-8 rounded-[10px] w-full ${isChecked ? "bg-[#00B07B] w-full" : "bg-gray-600 cursor-not-allowed"
                }`}
            >
              Continue
            </button>
            </NavLink>
            
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PremiumLoanPlan;
