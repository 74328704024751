import React, { useState } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { Oval } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import consultationData from "../../assets/consultation.json";
import markDone from "../../assets/markDone.json";
import Modal from "react-responsive-modal";
import { DashboardApi } from '../../../apis/DashboardApi';
import LoadingSpinner from "../../UI/LoadingSpinner";

function EduPreStudyLoan() {
    const navigate = useNavigate();
    const [loader, setLoader] = React.useState<any>(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const [openConsultation, setOpenConsultation] = useState(false);
    const onOpenConsultationModal = () => {
        // e.preventDefault();
        setOpenConsultation(true);
    };
    const onCloseConsultationModal = () => {
        setOpenConsultation(false);
        window.location.reload()
    };

    const [openDone, setOpenDone] = useState(false);
    const onOpenDoneModal = () => {
        // e.preventDefault();
        setOpenDone(true);
    };
    const onCloseDoneModal = () => setOpenDone(false);

    const [buttonText, setButtonText] = useState("Submit");

    const [userData, setUserdata] = useState({
        full_name: "",
        phone_no: "",
        email: "",
        country_of_sch: "",
        name_of_sch: "",
        course: "",
        resumption_date: "",

    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };


    const handleSubmit = React.useCallback(

        (e: any) => {
            e.preventDefault();

            setLoader(true)

            const userDatas = new FormData();
            userDatas.append('name', userData?.full_name);
            userDatas.append('phone_no', userData?.phone_no);
            userDatas.append('email', userData?.email);
            userDatas.append('country_of_sch', userData?.country_of_sch);
            userDatas.append('name_of_sch', userData?.name_of_sch);
            userDatas.append('course', userData?.course);
            userDatas.append('resumption_date', userData?.resumption_date);

            DashboardApi.applyStudyLoan(userDatas).then(
                (response: any) => {
                    if (response?.data) {
                        setLoader(false);
                        setButtonText("Submitted");
                        toast.success('Your form is successful');
                        // onOpenDoneModal();
                        onOpenConsultationModal();
                        // setUserdata({
                        //     full_name: "",
                        //     phone_no: "",
                        //     email: "",
                        //     country_of_sch: "",
                        //     name_of_sch: "",
                        //     course: "",
                        //     resumption_date: "",
                        // })
                        
                        

                    }
                }
            ).catch((err) => {
                if (err?.response?.status === 422) {
                    const responseMessage = err?.response?.data?.message || ""; // Extract the error message from the response
                    if (responseMessage === "The email has already been taken.") {
                        toast.error("This email has already been used");
                    } else {
                        toast.error("Form not rightly filled. Kindly cross-check.");
                    }
                    setLoader(false);
                } else {
                    toast.error("Some error occurred. Please try again.");
                    setLoader(false);
                }
            })
                .finally(() => {

                });
        },
        [userData]
    );
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen'>
                    <HeaderTitle title="Loan Process package " />
                    <div className='flex space-x-2'>
                        <div className=' place-content-center mt-2 cursor-pointer' onClick={() => { navigate(-1) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#6e7093" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6" /></svg>
                        </div>
                        <div className='mt-2'>
                            <h3 className='text-[14px] font-medium'>Kindly fill the information below to help you  with the loan package</h3>
                        </div>
                    </div>


                    <div className='bg-white rounded-[10px] lg:px-[20px] lg:py-[20px] px-[20px] pt-[20px] pb-[30px] mt-[14px]'>
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  ">
                                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                    <div className="flex-auto  ">
                                        <div className="flex flex-wrap ">

                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="John William"
                                                        name="full_name"
                                                        required
                                                        value={userData?.full_name || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="080****"
                                                        name="phone_no"
                                                        required
                                                        value={userData?.phone_no || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="johnwilliam@gmail.com"
                                                        name="email"
                                                        required
                                                        value={userData?.email || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Country of school you received admission in
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="country_of_sch"
                                                        required
                                                        value={userData?.country_of_sch || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Name of the school you receive admission in
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="name_of_sch"
                                                        required
                                                        value={userData?.name_of_sch || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Course you are admitted for
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="course"
                                                        required
                                                        value={userData?.course || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Preferred resumption
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="resumption_date"
                                                        required
                                                        value={userData?.resumption_date || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-start  ">
                                <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}

                                    className="w-5 h-5 accent-green-600 bg-[#D9D9D9] border-green-600 rounded"
                                />
                                <label
                                    htmlFor="default-checkbox"
                                    className="ml-2 text-[15px]  text-black "
                                >
                                    I agree to use the loan for tuition fee
                                </label>
                            </div>

                            <div className='mt-5'>
                                <button
                                    type='submit'
                                    disabled={!isChecked}
                                    //  onClick={onOpenConsultationModal}
                                    className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] ${isChecked ? "bg-[#00B07B]" : "bg-gray-600 cursor-not-allowed"
                                        }`}>
                                    {loader ? (
                                        <>

                                            <span className="mr-2">{buttonText}</span>
                                            <LoadingSpinner /> {/* Show loader */}
                                        </>
                                    ) : (
                                        buttonText
                                    )}
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>

            <Modal open={openConsultation} onClose={onCloseConsultationModal} center styles={{
                    modal: {
                        borderRadius: '15px', // Add border-radius here
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                    },
                }}>
                <div className='md:w-[350px] w-[300px]'>
                    <div className=" flex justify-center">
                        <h4 className="tex-[#000000] md:text-[18px] text-[20px] text-center font-bold">
                            Register for free consultation
                        </h4>

                    </div>
                    <div className='flex justify-center mt-4'>
                        <span className='text-[12px] text-[#977E0C] bg-[#FFF6DA] rounded-[8px] py-[10px] px-[18px]'>Note : It’s Important you register for Consultation</span>
                    </div>
                    <div>
                        <div className='flex justify-center mt-4'>

                            <div>
                                <Lottie animationData={consultationData} loop={true} />
                            </div>
                        </div>
                        <div className='mt-3 flex justify-center'>
                            <NavLink to={"https://calendar.app.google/cEdf5effNYckaFK39"} target='_blank'>
                            <button
                                
                                // onClick={onCloseConsultationModal}
                                className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Book Consultation</button>
                            </NavLink>
                            
                        </div>
                    </div>
                </div>

            </Modal>

            <Modal open={openDone} onClose={onCloseDoneModal} center styles={{
                modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                },
            }}>
                <div className='md:w-[350px] w-[300px]'>

                    <div className='flex justify-center mt-4'>
                        <div>
                            <Lottie animationData={markDone} loop={true} />
                        </div>
                    </div>

                    <div className=" flex justify-center mt-5">
                        <h4 className="tex-[#000000] md:text-[18px] text-[20px] text-center font-bold">
                            Study Loan Application Submitted
                        </h4>

                    </div>
                    <div>
                        <p className='text-center'>You application for Loan processing have been submitted successfully </p>
                    </div>

                    <div className='flex justify-center mt-5'>
                        <button
                            onClick={onCloseDoneModal}
                            className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Done</button>
                    </div>

                </div>

            </Modal>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default EduPreStudyLoan