import React, { useState } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { Oval } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from "react-router-dom";
import SCHOOLDATA from "../../assets/schoolsData.json";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Lottie from "lottie-react";
import consultationData from "../../assets/consultation.json";
import markDone from "../../assets/markDone.json";
import Modal from "react-responsive-modal";
import { DashboardApi } from '../../../apis/DashboardApi';
import LoadingSpinner from "../../UI/LoadingSpinner";

function EduPreSchool() {
    const navigate = useNavigate();
    const [loader, setLoader] = React.useState<any>(false);
    const [buttonText, setButtonText] = React.useState<any>("Submit");

    const [openConsultation, setOpenConsultation] = useState(false);
    const onOpenConsultationModal = () => {
        // e.preventDefault();
        setOpenConsultation(true);
    };
    const onCloseConsultationModal = () => {
        setOpenConsultation(false);
        window.location.reload()
    };

    const [openDone, setOpenDone] = useState(false);
    const onOpenDoneModal = () => {
        // e.preventDefault();
        setOpenDone(true);
    };
    const onCloseDoneModal = () =>{setOpenDone(false);setButtonText("Submit")} ;


    const [showDocument, setShowDocument] = useState<any>("");
    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            height: 46,
            minHeight: 46,
            background: "#FFFFFF",
            color: "#1F2337",
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            color: "#1F2337", // Custom colour
        }),
        indicatorSeparator: (base: any) => ({
            ...base,
            display: "none",
        }),
        menu: (base: any) => ({
            ...base,
            background: "white",
            color: "#1F2337",
        }),
        input: (defaultStyles: any) => {
            return {
                ...defaultStyles,
                color: "black",
            };
        },
        singleValue: (defaultStyles: any) => {
            return {
                ...defaultStyles,
                color: "black",
            };
        },
    };
    function customTheme(theme: any) {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "#1DB459",
                primary: "#00B07B",
            },
        };
    }
    const [stateOptionsS, setStateOptionsS] = useState<any>([]);
    const [userData, setUserdata] = useState({
        name: "",
        phone_no: "",
        email: "",
        home_address: "",
        state_of_residency: "",
        country_of_residency: "",
        country_of_interest: "",
        course_of_interest: ""
    });

    const optionCountriesS: any = [];
    // const optionState: any = [];
    SCHOOLDATA.map((item) => {
        optionCountriesS.push({ label: item.name, value: item.name });
    });

    const handleSelectChangeS = (name: any, option: any) => {
        setUserdata((prevData: any) => ({ ...prevData, [name]: option.value }));

        if (name === "country_of_interest") {
            const selectedCountry = SCHOOLDATA.find(
                (country) => country.name === option.value
            );
            const states = selectedCountry
                ? selectedCountry.details.map((state) => ({
                    label: state.name,
                    value: state.name,
                }))
                : [];
            setStateOptionsS(states);
        }
    };

    const [selectedPics1, setSelectedPics1] = React.useState<any>("No selected file");
    const changeHandler1 = (event: any) => {
        // console?.log(2)
        const target = event.target;
        let size = target.files[0].size / 1048576.0;
        let targetName = target.name;
        if (target.files && target.files[0]) {
            if (size > 3) {
                if (targetName == "bsc_cert") {
                    target.value = "";
                    toast.warn("File too large");
                }
            }
            if (size <= 3) {
                if (targetName == "bsc_cert") {
                    setSelectedPics1(event.target.files[0]);
                }
            }
        }
    };

    const [selectedPics2, setSelectedPics2] = React.useState<any>("No selected file");
    const changeHandler2 = (event: any) => {
        const target = event.target;
        let size = target.files[0].size / 1048576.0;
        let targetName = target.name;
        if (target.files && target.files[0]) {
            if (size > 3) {
                if (targetName == "bsc_transcript") {
                    target.value = "";
                    toast.warn("File too large");
                }
            }
            if (size <= 3) {
                if (targetName == "bsc_transcript") {
                    setSelectedPics2(event.target.files[0]);
                }
            }
        }
    };

    const [selectedPics3, setSelectedPics3] = React.useState<any>("No selected file");
    const changeHandler3 = (event: any) => {
        const target = event.target;
        let size = target.files[0].size / 1048576.0;
        let targetName = target.name;
        if (target.files && target.files[0]) {
            if (size > 3) {
                if (targetName == "weac_neco_cert") {
                    target.value = "";
                    toast.warn("File too large");
                }
            }
            if (size <= 3) {
                if (targetName == "weac_neco_cert") {
                    setSelectedPics3(event.target.files[0]);
                }
            }
        }
    };

    const [selectedPics4, setSelectedPics4] = React.useState<any>("No selected file");
    const changeHandler4 = (event: any) => {
        const target = event.target;
        let size = target.files[0].size / 1048576.0;
        let targetName = target.name;
        if (target.files && target.files[0]) {
            if (size > 3) {
                if (targetName == "passport_data") {
                    target.value = "";
                    toast.warn("File too large");
                }
            }
            if (size <= 3) {
                if (targetName == "passport_data") {
                    setSelectedPics4(event.target.files[0]);
                }
            }
        }
    };

    const [selectedPics5, setSelectedPics5] = React.useState<any>("No selected file");
    const changeHandler5 = (event: any) => {
        const target = event.target;
        let size = target.files[0].size / 1048576.0;
        let targetName = target.name;
        if (target.files && target.files[0]) {
            if (size > 3) {
                if (targetName == "recommend_one") {
                    target.value = "";
                    toast.warn("File too large");
                }
            }
            if (size <= 3) {
                if (targetName == "recommend_one") {
                    setSelectedPics5(event.target.files[0]);
                }
            }
        }
    };

    const [selectedPics6, setSelectedPics6] = React.useState<any>("No selected file");
    const changeHandler6 = (event: any) => {
        const target = event.target;
        let size = target.files[0].size / 1048576.0;
        let targetName = target.name;
        if (target.files && target.files[0]) {
            if (size > 3) {
                if (targetName == "recommend_two") {
                    target.value = "";
                    toast.warn("File too large");
                }
            }
            if (size <= 3) {
                if (targetName == "recommend_two") {
                    setSelectedPics6(event.target.files[0]);
                }
            }
        }
    };

   

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const handleSubmit = React.useCallback(

        (e: any) => {
            e.preventDefault();
            setLoader(true)

            const userDatas = new FormData();
            userDatas.append('full_name', userData?.name);
            userDatas.append('phone_no', userData?.phone_no);
            userDatas.append('email', userData?.email);
            userDatas.append('home_address', userData?.home_address);
            userDatas.append('state_of_residency', userData?.state_of_residency);
            userDatas.append('country_of_residency', userData?.country_of_residency);
            userDatas.append('country_of_interest', userData?.country_of_interest);
            userDatas.append('course_of_interest', userData?.course_of_interest);

            DashboardApi.admissionProcess(userDatas).then(
                (response: any) => {
                    if (response?.data) {
                        setLoader(false);
                        setButtonText("Submitted");
                        toast.success('Your School form is successful');
                        // onOpenDoneModal();
                        onOpenConsultationModal();
                        setUserdata({
                            name: "",
                            phone_no: " ",
                            email: " ",
                            home_address: " ",
                            state_of_residency: " ",
                            country_of_residency: "",
                            country_of_interest: " ",
                            course_of_interest: " ",

                        })

                       

                    }
                }
            ).catch((err) => {
                if (err?.response?.status === 422) {
                    const responseMessage = err?.response?.data?.message || ""; // Extract the error message from the response
                    if (responseMessage === "The email has already been taken.") {
                        toast.error("This email has already been used");
                    } else {
                        toast.error("Form not rightly filled. Kindly cross-check.");
                    }
                    setLoader(false);
                } else {
                    toast.error("Some error occurred. Please try again.");
                    setLoader(false);
                }
            }).finally(() => {

            });
        },
        [userData]
    );


    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen'>
                    <HeaderTitle title="Admission Process Application" />

                    <div className='flex space-x-2'>
                        <div className=' place-content-center mt-2 cursor-pointer' onClick={() => { navigate(-1) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#6e7093" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6" /></svg>
                        </div>
                        <div className='mt-2'>
                            <h3 className='text-[14px] font-medium'>Kindly fill the information below to help you with your Admission</h3>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='bg-white rounded-[10px] lg:px-[20px] lg:py-[20px] px-[20px] pt-[20px] pb-[30px] mt-[14px]'>

                            <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  ">
                                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                    <div className="flex-auto  ">
                                        <div className="flex flex-wrap ">

                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="John William "
                                                        name="name"
                                                        required
                                                        value={userData?.name || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="080****"
                                                        name="phone_no"
                                                        required
                                                        value={userData?.phone_no || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="johnwilliam@gmail.com"
                                                        name="email"
                                                        required
                                                        value={userData?.email || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        House address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="home_address"
                                                        required
                                                        value={userData?.home_address || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        State of Residency
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="state_of_residency"
                                                        required
                                                        value={userData?.state_of_residency || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Country of Residency
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="country_of_residency"
                                                        required
                                                        value={userData?.country_of_residency || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Select countries of interest
                                                    </label>
                                                    <Select
                                                        name="country_of_interest"
                                                        options={optionCountriesS}
                                                        isSearchable
                                                        theme={customTheme}
                                                        styles={customStyles}
                                                        required

                                                        placeholder="select country"
                                                        noOptionsMessage={() => "Country not found"}
                                                        onChange={(option) =>
                                                            handleSelectChangeS("country_of_interest", option)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Course of interest
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="course_of_interest"
                                                        required
                                                        value={userData?.course_of_interest || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <button
                                    type='submit'
                                    // onClick={onOpenConsultationModal}
                                    className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>
                                    {loader ? (
                                        <>

                                            <span className="mr-2">{buttonText}</span>
                                            <LoadingSpinner /> {/* Show loader */}
                                        </>
                                    ) : (
                                        buttonText
                                    )}
                                </button>
                            </div>

                        </div>
                        {/* <div className='bg-white rounded-[10px] lg:px-[20px] lg:py-[20px] px-[20px] pt-[20px] pb-[30px] mt-[20px]'>
                            <div>
                                <h3 className='text-[20px] font-semibold mb-4 text-center'>Do you have any of the document listed below</h3>
                                <p className='text-[14px] text-[#616161] text-center'>BSC Certificate, BSC transcript, Curriculum Vitae (CV), WEAC / NECO Certificate ,<br /> International passport data page, Letter of recommendation </p>
                            </div>
                            <div className='flex justify-center gap-5 mt-5'>
                                <div
                                    onClick={() => setShowDocument("IHave")}
                                    className='bg-[#00B07B] text-white rounded-[50px] px-[30px] py-[10px] cursor-pointer'>I have all the documents</div>
                                <div
                                    onClick={() => setShowDocument("IHave")}
                                    className='bg-[#fff] text-[#00B07B] border border-[#00B07B] rounded-[50px] px-[30px] py-[10px] cursor-pointer'>I have some of the documents</div>
                                <div className='bg-[#fff] text-[#00B07B] border border-[#00B07B] rounded-[50px] px-[30px] py-[10px] cursor-pointer'>I don’t have any document</div>
                            </div>


                        </div> */}

                        {/* <div className={showDocument == "IHave" ? "block" : "hidden"}>
                            <div className='bg-white rounded-[10px] lg:px-[20px] lg:py-[20px] px-[20px] pt-[20px] pb-[30px] mt-[20px]'>
                                <h3 className='text-[20px] font-semibold mb-4'>Upload document</h3>

                                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto ">
                                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                        <div className="flex-auto  ">
                                            <div className="flex flex-wrap ">

                                                <div className="w-full lg:w-6/12 lg:pr-4 mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        BSC Certificate (Optional)
                                                    </label>
                                                    <div className="border-2 border-gray-300 rounded-md pt-10 pb-8 h-[160px] text-center">
                                                        <label htmlFor="bsc_cert">
                                                            <h3 className="text-[12px] font-medium">
                                                                {selectedPics1 !== "No selected file"
                                                                    ? selectedPics1?.name
                                                                    : "Click to upload or drag and drop"}{" "}
                                                            </h3>
                                                            <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                                                                {selectedPics1 !== "No selected file"
                                                                    ? ""
                                                                    : "PDF, PNG, JPG (max. 3 mb)"}{" "}
                                                            </h3>
                                                            <div className="mt-5">
                                                                <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                                                                    Choose a File
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="bsc_cert"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                name="bsc_cert"
                                                                onChange={changeHandler1}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 lg:pr-4 mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        BSC Transcript (Optional)
                                                    </label>
                                                    <div className="border-2 border-gray-300  rounded-md pt-10 pb-8 h-[160px] text-center">
                                                        <label htmlFor="bsc_transcript">
                                                            <h3 className="text-[12px] font-medium">
                                                                {selectedPics2 !== "No selected file"
                                                                    ? selectedPics2?.name
                                                                    : "Click to upload or drag and drop"}{" "}
                                                            </h3>
                                                            <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                                                                {selectedPics2 !== "No selected file"
                                                                    ? ""
                                                                    : "PDF, PNG, JPG (max. 3 mb)"}{" "}
                                                            </h3>
                                                            <div className="mt-5">
                                                                <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                                                                    Choose a File
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="bsc_transcript"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                name="bsc_transcript"
                                                                onChange={changeHandler2}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 lg:pr-4 mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        WEAC/ NECO certificate (Optional)
                                                    </label>
                                                    <div className="border-2 border-gray-300  rounded-md pt-10 pb-8 h-[160px] text-center">
                                                        <label htmlFor="weac_neco_cert">
                                                            <h3 className="text-[12px] font-medium">
                                                                {selectedPics3 !== "No selected file"
                                                                    ? selectedPics3?.name
                                                                    : "Click to upload or drag and drop"}{" "}
                                                            </h3>
                                                            <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                                                                {selectedPics3 !== "No selected file"
                                                                    ? ""
                                                                    : "PDF, PNG, JPG (max. 3 mb)"}{" "}
                                                            </h3>
                                                            <div className="mt-5">
                                                                <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                                                                    Choose a File
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="weac_neco_cert"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                name="weac_neco_cert"
                                                                onChange={changeHandler3}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 lg:pr-4 mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        International passport data page (Optional)
                                                    </label>
                                                    <div className="border-2 border-gray-300 rounded-md pt-10 pb-8 h-[160px] text-center">
                                                        <label htmlFor="passport_data">
                                                            <h3 className="text-[12px] font-medium">
                                                                {selectedPics4 !== "No selected file"
                                                                    ? selectedPics4?.name
                                                                    : "Click to upload or drag and drop"}{" "}
                                                            </h3>
                                                            <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                                                                {selectedPics4 !== "No selected file"
                                                                    ? ""
                                                                    : "PDF, PNG, JPG (max. 3 mb)"}{" "}
                                                            </h3>
                                                            <div className="mt-5">
                                                                <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                                                                    Choose a File
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="passport_data"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                name="passport_data"
                                                                onChange={changeHandler4}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 lg:pr-4 mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Letter of recommendation 1  (Optional)
                                                    </label>
                                                    <div className="border-2 border-gray-300 rounded-md pt-10 pb-8 h-[160px] text-center">
                                                        <label htmlFor="recommend_one">
                                                            <h3 className="text-[12px] font-medium">
                                                                {selectedPics5 !== "No selected file"
                                                                    ? selectedPics5?.name
                                                                    : "Click to upload or drag and drop"}{" "}
                                                            </h3>
                                                            <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                                                                {selectedPics5 !== "No selected file"
                                                                    ? ""
                                                                    : "PDF, PNG, JPG (max. 3 mb)"}{" "}
                                                            </h3>
                                                            <div className="mt-5">
                                                                <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                                                                    Choose a File
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="recommend_one"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                name="recommend_one"
                                                                onChange={changeHandler5}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 lg:pr-4 mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Letter of recommendation 2  (Optional)
                                                    </label>
                                                    <div className="border-2 border-gray-300 rounded-md pt-10 pb-8 h-[160px] text-center">
                                                        <label htmlFor="recommend_two">
                                                            <h3 className="text-[12px] font-medium">
                                                                {selectedPics6 !== "No selected file"
                                                                    ? selectedPics6?.name
                                                                    : "Click to upload or drag and drop"}{" "}
                                                            </h3>
                                                            <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                                                                {selectedPics6 !== "No selected file"
                                                                    ? ""
                                                                    : "PDF, PNG, JPG (max. 3 mb)"}{" "}
                                                            </h3>
                                                            <div className="mt-5">
                                                                <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                                                                    Choose a File
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="recommend_two"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                name="recommend_two"
                                                                onChange={changeHandler6}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className='mt-5'>
                                    <button
                                        onClick={onOpenConsultationModal}
                                        className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Continue</button>
                                </div>


                            </div>
                        </div> */}

                    </form>

                </div>
            </div>

            <Modal open={openConsultation} onClose={onCloseConsultationModal} center styles={{
                    modal: {
                        borderRadius: '15px', // Add border-radius here
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                    },
                }}>
                <div className='md:w-[350px] w-[300px]'>
                    <div className=" flex justify-center">
                        <h4 className="tex-[#000000] md:text-[18px] text-[20px] text-center font-bold">
                            Register for free consultation
                        </h4>

                    </div>
                    <div className='flex justify-center mt-4'>
                        <span className='text-[12px] text-[#977E0C] bg-[#FFF6DA] rounded-[8px] py-[10px] px-[18px]'>Note : It’s Important you register for Consultation</span>
                    </div>
                    <div>
                        <div className='flex justify-center mt-4'>

                            <div>
                                <Lottie animationData={consultationData} loop={true} />
                            </div>
                        </div>

                        <div className='mt-3 flex justify-center'>
                            <NavLink to={"https://calendar.app.google/cEdf5effNYckaFK39"} target='_blank'>
                            <button
                                
                                // onClick={onCloseConsultationModal}
                                className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Book Consultation</button>
                            </NavLink>
                            
                        </div>
                    </div>
                </div>

            </Modal>

            <Modal open={openDone} onClose={onCloseDoneModal} center
                styles={{
                    modal: {
                        borderRadius: '15px', // Add border-radius here
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                    },
                }}>
                <div className='md:w-[350px] w-[300px]'>

                    <div className='flex justify-center mt-4'>
                        <div>
                            <Lottie animationData={markDone} loop={true} />
                        </div>
                    </div>

                    <div className=" flex justify-center mt-5">
                        <h4 className="tex-[#000000] md:text-[18px] text-[20px] text-center font-bold">
                            Admission application submitted
                        </h4>

                    </div>
                    <div>
                        <p className='text-center'>You application for Admission processing have been submitted successfully </p>
                    </div>

                    <div className='flex justify-center mt-5'>
                        <button
                            onClick={onCloseDoneModal}
                            className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Done</button>
                    </div>

                </div>

            </Modal>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default EduPreSchool