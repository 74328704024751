import React, { useState } from "react";
import UserNavbar from "../../Navbars/UserNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";

const PremiumLoanPlanNot = () => {
  // const userLoginData = useSelector((state:any) => state.data.credit);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // const creditEligibility = useSelector((state: any) => state.data.loanCreditEligibility.value);
  const [open, setOpen] = useState(false);
  const onOpenModal = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const handleSubmit = () => {
    navigate("/loans-premium-eligibility");
  };

  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className="lg:mt-3 w-full lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          <div className="flex justify-center pt-8 pb-4">
            <img src="/images/pana.svg" alt="hero" />
          </div>
          {/* <h4 className="text-center text-[30px] font-bold">
            You are not eligible
          </h4> */}
          {/* <h4 className="text-center text-[#6F6C90] text-[16px]">
            Your application failed because you don't have admission letter, do
            not worry you can proceed to Afripay Premium where you can get both
            admission and loan.
          </h4> */}
          <h4 className="text-center text-[30px] font-bold">You are not qualified</h4>

          <h4 className="text-center text-[#545151] text-[14px] font-[400] pt-1">
          Unfortunately, your school does not qualify for our loan programs
          </h4>
          {/* <div className="flex justify-center space-x-6 mt-4">
            <div className="flex space-x-2">
              <SvgElement type={icontypesEnum.FLATCANCEL} />
              <h3 className="text-[#282828] text-[14px] pt-1">You do not have an admission letter</h3>
            </div>
            <div className="flex space-x-2">
              <SvgElement type={icontypesEnum.FLATCANCEL} />
              <h3 className="text-[#282828] text-[14px] pt-1">Your course is not aligned with the required fields.</h3>
            </div>
          </div> */}

          <div className="mt-10">
            <h1 className="text-center text-[#222222] text-[20px] font-bold">What’s next</h1>
            <p className="text-center text-[#7A7979] font-[500] text-[16px] pt-1">We recommend checking out EduPremium plus, which may offer you the support the need</p>
          </div>

          <div className="flex justify-center w-full pt-6">
            <div className="flex flex-col lg:flex-row justify-center bg-[#26A768] rounded-[8px] py-10 px-7">
            
              <div className=" text-center ">
                <span className=" border border-dotted border-[#fff] rounded-[48px] text-[#fff] text-[16px] text-center py-[14px] px-[26px] font-bold">
               EduPremium Plus
                </span>
                <h5 className="text-white text-[14px] mt-6 max-w-[343px] font-[400]">
                  This is intended for student who did not have admission but have the necessary document to get admission and the loan 
                </h5>
                <div>
                 
                </div>
                <h4 className="text-white text-[28px] font-bold pt-3">
                  $450
                </h4>
                <div className="mt-5">
                  <NavLink to="/loans-premium-plan" className="">
                  <button
                    
                    // onClick={onOpenModal}
                    className="py-[10px] px-[70px] bg-white text-[16px] font-normal text-[#00B07B] rounded-[8px] border border-[#ff] "
                  >
                    Get Started
                  </button>
                  </NavLink>
                </div>
              </div>

              <div className=" md:border-l-2 md:border-[#C2EDE0] border-none pl-4 md:pr-10 pr-4 flex justify-center text-center items-center">
              <div className="">
              <h5 className="text-white text-left text-[18px] font-semibold">
                  Package Benefit
                  </h5>
                <div className="mt-3">
                <div className="flex space-x-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Access to over 2500 institutions and over 40 countries</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Admission processing for 6 institutions </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Professional CV Revamp</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Personal Statement  Writing for all 6 application </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Personal processing of the loan on your behalf </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Professional guidance on loan required document  </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Access to loans that do not required guarantor or collateral</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]"> Access to loans with good and flexible  repayment term</h3>
                </div>

                </div>
              </div>
            </div>
            </div>

           
          </div>
        </div>
      </div>

      <Modal open={open} onClose={onCloseModal} center>
        <div className=" flex justify-center">
          <h4 className="tex-[#000000] md:text-[18px] text-[16px] text-center font-bold">
            AfriPay Non-Refundable Policy for Loan Applications
          </h4>
        </div>
        <div className="md:w-[600px] body-font font-poppins">
          <div className="flex justify-center mt-9">
            {/* <h4 className="text-center text-[14px]">
                 My afripay wallet balance
               </h4> */}
          </div>
          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Introduction</h4>
            <h4 className=" text-[14px]">
              This policy outlines the non-refundable terms for loan application
              fees associated with AfriPay’s educational loan products:
              EduSilver, EduGold, and EduPremium. By applying for any of these
              loans, you agree to the terms and conditions stated in this
              policy.
            </h4>
          </div>
          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Loan Products Covered</h4>
            <h4 className=" text-[14px]">
              This policy applies to the following :
              <br />- EduSilver: This product checks your school, country, and
              admission eligibility. The application for EduSilver is free of
              charge.
            </h4>
            <h4 className=" text-[14px] mt-2">
              - EduGold: This product includes all the benefits of EduSilver,
              checks your credit history. If your credit score is good, then you
              can proceed to apply for the loan. The application fee for EduGold
              is $4, which is non-refundable.
            </h4>
            <h4 className=" text-[14px] mt-2">
              - EduPremium: This product is intended for students who do not yet
              have admission but have the necessary documents to secure
              admission and a loan. The application fee for EduPremium is $450,
              which is non-refundable.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Application Fee</h4>
            <h4 className=" text-[14px]">
              Each loan application is subject to a non-refundable application
              fee. The application fee covers the cost of processing your loan
              application and is required to submit your application for review.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Non-Refundable Terms</h4>
            <h4 className=" text-[14px]">
              The application fee is strictly non-refundable
            </h4>

          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">
              Acknowledgment and Acceptance
            </h4>
            <h4 className=" text-[14px]">
              By submitting a loan application, applicants acknowledge and
              accept that the application fee is non-refundable. It is the
              responsibility of the applicant to ensure that they meet all
              eligibility criteria and provide accurate information before
              submitting their application.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Contact Information</h4>
            <h4 className=" text-[14px]">
              For any questions or concerns regarding this non-refundable
              policy, applicants can contact AfriPay customer service at <span className='text-[#209D5A]'>hello@goafripay.com</span>.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Changes to This Policy</h4>
            <h4 className=" text-[14px]">
              AfriPay reserves the right to modify or update this policy at any
              time. Any changes will be effective immediately upon posting on
              our official website. Applicants are encouraged to review the
              policy regularly for any updates.
            </h4>
          </div>

          <div className="flex flex-col gap-1 mt-2">
            <h4 className=" text-[16px] font-bold">Governing Law</h4>
            <h4 className=" text-[14px]">
              This policy is governed by the laws of Federal Republic of
              Nigeria. Any disputes arising from or related to this policy shall
              be subject to the exclusive jurisdiction of the courts of Federal
              Republic of Nigeria
            </h4>
          </div>

          <div className="flex items-start mt-5 ">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="w-5 h-5 accent-green-600 bg-[#D9D9D9] border-green-600 rounded"
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-[15px]  text-gray-900 "
            >
              I have read this and I understand
            </label>
          </div>
          <div className="flex justify-center  pt-2">
            <button
              onClick={handleSubmit}
              // onClick={(e: any) => handleSubmit2(e)}
              // className="text-white disabled:bg-gray-600 bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
              disabled={!isChecked}
              className={`text-white flex justify-center items-center py-3 rounded-[10px] w-full ${isChecked ? "bg-[#00B07B]" : "bg-gray-600 cursor-not-allowed"
                }`}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PremiumLoanPlanNot;
