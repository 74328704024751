import { AxiosPromise } from "axios";
import configs from "../configs";
import { DashboardLiveApi } from "./live/DashboardLiveApis";



export class DashboardApi {
    private static dashboardApi: DashboardLiveApi = new DashboardLiveApi();
    
    static uploadBankDoc(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.uploadBankDoc(data);
        }
    }


    static uploadKyc(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.uploadKyc(data);
        }
    }

    static applyStudentLoan(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.applyStudentLoan(data);
        }
    }

    static makeSchoolPayment(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.makeSchoolPayment(data);
        }
    }   

    static makePayment(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.makePayment(data);
        }
    } 


    static makePaymentFromWallet(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.makePaymentFromWallet(data);
        }
    } 


    

    static makePaymentWithInterswitch(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.makePaymentWithInterswitch(data);
        }
    } 

    static addSchool(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.addSchool(data);
        }
    }  

    static getAllSchool(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getAllSchool(pageNo,query);
        }
    } 

    static getSchoolList(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getSchoolList();
        }
    } 


    

    static getPaymentHistory(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getPaymentHistory(pageNo,query);
        }
    }

    static getPendingPaymentHistory(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getPendingPaymentHistory();
        }
    }


    

    static getPaymentDetail(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getPaymentDetail(id);
        }
    }
    
    

    static getCallback(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getCallback(reference);
        }
    }

    static verifyLoan(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.verifyLoan();
        }
    }

    static verifyKyc(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.verifyKyc();
        }
    }

    

    static swap(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.swap();
        }
    }


    static generateQuote(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.generateQuote(data);
        }
    }

    

    static createDynamicAccount(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.createDynamicAccount(data);
        }
    }


    static getDashboardData(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getDashboardData();
        }
    }

    static verifyInterswitchPayment(reference:any,amount:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.verifyInterswitchPayment(reference,amount);
        }
    }


    static createCreditEligibility(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.createCreditEligibility(data);
        }
    }

    static getSingleCreditEligibity(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getSingleCreditEligibity(id);
        }
    } 

    static createSchoolForm(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.createSchoolForm(data);
        }
    }

    static applyStudyLoan(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.applyStudyLoan(data);
        }
    }

    static admissionProcess(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.admissionProcess(data);
        }
    }


    static getSingleSchoolForm(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getSingleSchoolForm(id);
        }
    }

}