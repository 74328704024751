import React from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import ComingSoon from './ComingSoon';
function Savings() {
  return (
    <>
    <Sidebar />
    <div className="relative md:ml-60">
        <UserNavbar />
        <div className='lg:mt-3 lg:px-5 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen'>
          <ComingSoon/>
        </div>
      </div>
    </>
  )
}

export default Savings