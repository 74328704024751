import React, { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { History } from "history";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
import { PaymentApis } from "../../../apis/paymentApis";



function VerifyWalletPaymentInNaira() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [subValue, setSubValue] = React.useState(1);




  React.useEffect(() => {
    if (searchParams.get('status') == 'cancelled') {
      toast.error('Payment Failed');
      navigate('/topup');
      
    }
  }
  );

  if (searchParams.get('tx_ref')) {
    let data
    if (searchParams.get('status') == 'cancelled') {
      navigate('/topup');
    } else {
      PaymentApis.verifyWalletTopupInNaira(searchParams.get('tx_ref')).then(
        (response: any) => {
          if (response?.data) {
            // navigate('/wallet');
            if (response?.data?.success === true) {
             
              navigate('/new-dashboard');
            }
          } else {
            // toast.warn('Invalid Login Credentials');
          }
        }
      ).catch(function (error: any) {
        // handle error
        console.log(error.response.data);
      }).finally(() => {
        navigate('/new-dashboard');
        // toast.error("No Internet Connection");

      });
    }

  }

  return (
    <>
      <div className="flex justify-center mt-[50vh]">
        <div className="flex flex-col">
          <Oval
            visible={true}
            height="80"
            width="80"
            color="#14B266"
            secondaryColor="#E6F1FC"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />

          <span className="text-[12px]">Please Wait!!!</span>
        </div>

      </div>


    </>
  );
}

export default VerifyWalletPaymentInNaira;
