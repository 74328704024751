import React, { useState } from "react";
import HeaderTitle from "../../ReuseableComponent/HeaderTitle";
import UserNavbar from "../../Navbars/UserNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut, getElementsAtEvent } from "react-chartjs-2";
import Select from "react-select";
import COUNTRYDATA from "../../assets/country-list.json";
import SCHOOLDATA from "../../assets/schoolsData.json";
import PROGRAMTYPE from "../../assets/programType.json";
import CreatableSelect from "react-select/creatable";
import { AppDispatch } from "../../reducer/Store";
import { connect, useDispatch } from "react-redux";
import { credit } from "../../reducer/loanCredit";

const LoanSilverEligibility = () => {
  const dispatch: AppDispatch = useDispatch();


  const [selectedOption, setSelectedOption] = useState("");
  const [stemselectedOption, setStemSelectedOption] = useState("");
  const navigate = useNavigate();
  const [dataArray, setDataArray] = useState<number[]>([0, 100]);
  const [countryValue, setCountryValue] = useState([]);
  const [stateOptions, setStateOptions] = useState<any>([]);
  const [stateOptionsS, setStateOptionsS] = useState<any>([]);

  ChartJs.register(...registerables, Tooltip, Title, ArcElement, Legend);

  // Custom plugin to draw text in the center
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: function (chart: any) {
      const { width, height, ctx } = chart;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${
        (dataArray[0] / dataArray.reduce((a, b) => a + b, 0)) * 100
      }%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const data = {
    datasets: [
      {
        data: dataArray,
        backgroundColor: ["#00B07B", "#D9D9D9"],
        cutout: "70%", // Adjust this value to make the doughnut thinner
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Send", "Receive"],
  };



  const options = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  const [userData, setUserdata] = useState({
    nationality: "",
    admission_letter: "",
    country_of_residence: "",
    program_type: "",
    stem_course: "",
    course_of_study: "",
    // country_of_school_acceptance: "",
    // school_admitted: "",
    country_of_School_accepted_to: "",
    school_accepted_to: ""
  });

  const optionCountriesS: any = [];
  // const optionState: any = [];
  SCHOOLDATA.map((item) => {
    optionCountriesS.push({ label: item.name, value: item.name });
  });

  const optionCountries: any = [];
  const optionState: any = [];
  COUNTRYDATA.map((item) => {
    optionCountries.push({ label: item.name, value: item.name });
  });

  const optionProgram: any = [];
  // const optionState: any = [];
  PROGRAMTYPE.map((item) => {
    optionProgram.push({ label: item.label, value: item.value });
  });

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      height: 46,
      minHeight: 46,
      background: "#FFFFFF",
      color: "#1F2337",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#1F2337", // Custom colour
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    menu: (base: any) => ({
      ...base,
      background: "white",
      color: "#1F2337",
    }),
    input: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
    singleValue: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
  };

  function customTheme(theme: any) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#1DB459",
        primary: "#00B07B",
      },
    };
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserdata((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (name:any, option:any) => {
    setUserdata((prevData:any) => ({ ...prevData, [name]: option.value }));
  };
  const handleSelectChangeS = (name: any, option: any) => {
    setUserdata((prevData: any) => ({ ...prevData, [name]: option.value }));

    if (name === "country_of_School_accepted_to") {
      const selectedCountry = SCHOOLDATA.find(
        (country) => country.name === option.value
      );
      const states = selectedCountry
        ? selectedCountry.details.map((state) => ({
            label: state.name,
            value: state.name,
          }))
        : [];
      setStateOptionsS(states);
    }
  };

  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      admission_letter: e.target.value,
    }));
  };
  const handleStemOptionChange = (e: any) => {
    setStemSelectedOption(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      stem_course: e.target.value,
    }));
  };

  const handleCourseChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (userData.admission_letter === "yes") {
      navigate("/loan-gold-qualify");
      // dispatch(credit(userData))
      sessionStorage.setItem("loanCredit", JSON.stringify(userData));
    } else {
      navigate("/loans-premium-plan-direct");
      sessionStorage.setItem("loanCredit", JSON.stringify(userData));
    }
  };
  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className="lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          <HeaderTitle title="Loan" />

          <div className="flex justify-center">
            <div className="pt-3 pb-7">
              {/* <h3 className="text-[#000000] text-center text-[36px] font-[600]">
                Free Eligibility Test
              </h3> */}
              <h5 className="text-[#5F5D5D] text-center text-[20px] font-[600]">
                If you have admission to study in US, UK, Canada or any of the
                eligible countries, please fill out the International Student
                Loan Qualification Form.
              </h5>
            </div>
          </div>

          <div className="grid lg:grid-cols-12 w-full gap-2">
            <div className="lg:col-span-8 bg-white pt-5 px-2 rounded-[10px]">
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="rounded-[10px] mb-3 py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Nationality
                    </h4>
                  </label>
                  <Select
                    name="nationality"
                    options={optionCountries}
                    isSearchable
                    theme={customTheme}
                    styles={customStyles}
                    required
                    // defaultInputValue={props.customer!.country}
                    // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                    placeholder="Select Option"
                    noOptionsMessage={() => "Country not found"}
                    onChange={(options: any) => {
                      handleSelectChange("nationality", options);
                      // setFieldValue("country", options!.value);
                      setCountryValue(options!.country);
                      optionState?.splice(0, optionState!.length);

                      COUNTRYDATA.filter(
                        (item) => item.name === options!.value
                      )
                      .map((item) => {
                        item.states.map((values) =>
                          optionState!.push({
                            label: values.name,
                            value: values.name,
                          })
                        );
                      });
                    }}
                  />
                  {/* <select
                    id="nationality"
                    name="nationality"
                    value={userData?.nationality}
                    onChange={handleChange}
                    className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                  >
                    <option selected>select option</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                  </select> */}
                </div>

                <div className="pt-6">
                  <label className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Do you have admission letter
                    </h4>
                  </label>
                  <div className="w-full grid grid-cols-2 pt-3 gap-3">
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="yes"
                        name="admission_letter"
                        value="yes"
                        checked={selectedOption === "yes"}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="no"
                        name="admission_letter"
                        value="no"
                        checked={selectedOption === "no"}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <label className="mb-3 rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Country of Residence
                    </h4>
                  </label>
                  <Select
                    name="country_of_residence"
                    options={optionCountries}
                    isSearchable
                    theme={customTheme}
                    required
                    styles={customStyles}
                    // defaultInputValue={props.customer!.country}
                    // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                    placeholder="Select Option"
                    noOptionsMessage={() => "Country not found"}
                    onChange={(options: any) => {
                      handleSelectChange("country_of_residence", options);
                      // setFieldValue("country", options!.value);
                      setCountryValue(options!.country);
                      optionState?.splice(0, optionState!.length);

                      COUNTRYDATA.filter(
                        (item) => item.name === options!.value
                      ).map((item) => {
                        item.states.map((values) =>
                          optionState!.push({
                            label: values.name,
                            value: values.name,
                          })
                        );
                      });
                    }}
                  />
                  {/* <select
                    id="country_of_residence"
                    name="country_of_residence"
                    value={userData?.country_of_residence}
                    onChange={handleChange}
                    className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                  >
                    <option selected>select option</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                  </select> */}
                </div>

                <div className="pt-6">
                  <label className="mb-3 rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Program Type
                    </h4>
                  </label>
                  <Select
                    name="program_type"
                    options={optionProgram}
                    isSearchable
                    theme={customTheme}
                    required
                    styles={customStyles}
                    placeholder="Select Option"
                    noOptionsMessage={() => "Program Type not found"}
                    onChange={(options: any) => {
                      handleSelectChange("program_type", options); 
                    }}
                  />
                  {/* <select
                    id="country_of_residence"
                    name="country_of_residence"
                    value={userData?.country_of_residence}
                    onChange={handleChange}
                    className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                  >
                    <option selected>select option</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                  </select> */}
                </div>

                <div className="pt-6">
                  <label className="mb-3 rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-center text-[16px] font-[500]">
                  Select  the country of your admitted school
                    </h4>
                  </label>

                  <Select
                    name="country_of_School_accepted_to"
                    options={optionCountriesS}
                    isSearchable
                    theme={customTheme}
                    styles={customStyles}
                    required
                    // defaultInputValue={props.customer!.country}
                    // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                    placeholder="Select Option"
                    noOptionsMessage={() => "Country not found"}
                    onChange={(option) =>
                      handleSelectChangeS("country_of_School_accepted_to", option)
                    }
                  />
                  {/* <select
                    id="country_of_residence"
                    name="country_of_residence"
                    value={userData?.country_of_residence}
                    onChange={handleChange}
                    className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                  >
                    <option selected>select option</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                  </select> */}
                </div>

                <div className="pt-6">
                  <label className=" mb-3 rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                    Select your admitted school
                    </h4>
                  </label>
                  <CreatableSelect
                    name="school_accepted_to"
                    options={stateOptionsS}
                    isSearchable
                    isClearable
                    required
                    theme={customTheme}
                    styles={{
                      ...customStyles,
                      noOptionsMessage: (base) => ({
                        ...base,
                        color: '#DC143C', // Set the text color to red
                      }),
                    }}
                    placeholder="Select Option"
                    noOptionsMessage={() => "Please select a country before proceeding to choose a school"}
                    onChange={(option) =>
                      handleSelectChangeS("school_accepted_to", option)
                    }
                  />
                </div>
                <div className="pt-6">
                  <label className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Is your course related to STEM?
                    </h4>
                  </label>
                  <div className="w-full grid grid-cols-2 pt-3 gap-3">
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="stem_yes"
                        name="stem_course"
                        value="yes"
                        checked={stemselectedOption === "yes"}
                        onChange={handleStemOptionChange}
                      />
                      <label htmlFor="stem_yes">Yes</label>
                    </div>
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="stem_no"
                        name="stem_course"
                        value="no"
                        checked={stemselectedOption === "no"}
                        onChange={handleStemOptionChange}
                      />
                      <label htmlFor="stem_no">No</label>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mt-4">
                <label className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Your course of study
                    </h4>
                  </label>
                  <div className="pt-3">
                  <input
                        type="text"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[6px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder=""
                        name="course_of_study"
                        required
                        //   defaultValue={userDetails?.first_name}
                        onChange={handleCourseChange}
                       
                      />
                  </div>
                     
                    </div>

                <div className="flex justify-center mb-10 pt-7">
                  <button
                    type="submit"
                    className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
            <div className="lg:col-span-4">
              <div className="border rounded-[10px] h-fit  w-full flex justify-center relative">
                <div>
                  <h4 className="text-[#000000] text-[20px] text-center pt-4 font-semibold">
                    EduSilver Stage
                  </h4>
                  <div className="p-4  md:w-[180px] md:h-[180px]">
                    <Doughnut
                      options={options}
                      data={data}
                      plugins={[centerTextPlugin]}
                    />
                  </div>
                  <div className="flex justify-center">
                    <h4>
                      On Progress <span className="text-[#00B07B]">0%</span>
                    </h4>
                  </div>
                  <div className="flex justify-center mt-3 mb-10">
                    <h4 className=" text-center max-w-[200px]">
                      Meet all requirements to advance to EduGold
                    </h4>
                  </div>
                </div>
              </div>

              <div className="rounded-[10px] mt-8">
                <div className="bg-white rounded-t-[10px] py-2 flex justify-center">
                  <h4 className="text-[#000000] text-[20px]">
                    What to do next
                  </h4>
                </div>
                <div className="bg-[#00B07B] rounded-b-[10px] flex justify-center pt-3 pb-5">
                  <h4 className="text-white text-[18px] text-center max-w-[300px]">
                    Answer the next Question about your admision letter
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default LoanSilverEligibility;
