import React from 'react'
import {  useNavigate, } from "react-router-dom";

function HeaderTitle({ title }) {
    const navigate = useNavigate();
  return (
    <>
    <div className='pt-5 flex space-x-3'>
        {/* <div className=' place-content-center mt-5' onClick={() => navigate(-1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#6e7093" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6"/></svg>
        </div> */}
        <div>
        <h1 className='font-semibold md:text-[22px] text-[18px]'>{ title }</h1>
        <hr className='w-[206px] h-1 bg-[#00B07B]'/>
        </div>
        
    </div>
    </>
  )
}

export default HeaderTitle