import React, { useState, useRef } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { DashboardApi } from '../../../apis/DashboardApi';
import { PaymentApis } from '../../../apis/paymentApis';
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { format } from 'date-fns';

function Transactions() {

    const [transactionList, setTransactionList] = React.useState<any>([]);

    const [transactionDetails, setTransactionDetails] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');

    const [open, setOpen] = useState(false);

    const onOpenModal = (id: any) => {
        PaymentApis.getTransactionHistoryById(id).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setTransactionDetails(response?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })

        setOpen(true);
    }
    const onCloseModal = () => setOpen(false);

    const contentRef = useRef<HTMLDivElement>(null);

    //         const generatePDF = async () => {
    //     const element = contentRef.current;
    //     if (element) {
    //       const canvas = await html2canvas(element);
    //       const imgData = canvas.toDataURL('image/png');
    //       const pdf = new jsPDF();
    //       const pdfWidth = pdf.internal.pageSize.getWidth();
    //       const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //       pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //       pdf.save('download.pdf');
    //     }
    //   };

    const generatePDF = async () => {
        const element = contentRef.current;
        // if (element) {
        //     const canvas = await html2canvas(element);
        //     const imgData = canvas.toDataURL('image/png');

        //     const pdf = new jsPDF('p', 'mm', 'a4'); 
        //     const pdfWidth = pdf.internal.pageSize.getWidth();
        //     const pdfHeight = pdf.internal.pageSize.getHeight();

        //     const imgWidth = pdfWidth - 20; 
        //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

        //     const marginTop = 10; 

        //     if (imgHeight > pdfHeight - 20) {
                
        //         let y = marginTop;
        //         const pageHeight = pdfHeight - 20;

        //         while (canvas.height > 0) {
        //             const canvasPortion = canvas.height > pageHeight ? pageHeight : canvas.height;
        //             pdf.addImage(
        //                 imgData,
        //                 'PNG',
        //                 10,
        //                 y,
        //                 imgWidth,
        //                 canvasPortion
        //             );
        //             canvas.height -= pageHeight;

        //             if (canvas.height > 0) {
        //                 pdf.addPage();
        //             }
        //         }
        //     } else {
               
        //         pdf.addImage(imgData, 'PNG', 10, marginTop, imgWidth, imgHeight);
        //     }

        //     pdf.save('transaction-details.pdf');
        // }
    };

    const generatePDF2 = async () => {
        const element = contentRef.current;
        if (element) {
          try {
            const canvas = await html2canvas(element, {
              scale: 2, // Ensure proper rendering on mobile
              useCORS: true, // Handle cross-origin issues
            });
            const imgData = canvas.toDataURL('image/png');
      
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
      
            const imgWidth = pdfWidth - 20;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
            const marginTop = 10;
      
            if (imgHeight > pdfHeight - 20) {
              // Multi-page handling
              let y = marginTop;
              const pageHeight = pdfHeight - 20;
      
              while (canvas.height > 0) {
                const canvasPortion = canvas.height > pageHeight ? pageHeight : canvas.height;
                pdf.addImage(imgData, 'PNG', 10, y, imgWidth, canvasPortion);
                canvas.height -= pageHeight;
      
                if (canvas.height > 0) pdf.addPage();
              }
            } else {
              pdf.addImage(imgData, 'PNG', 10, marginTop, imgWidth, imgHeight);
            }
      
            // Download for mobile
            const blob = pdf.output('blob');
            const url = URL.createObjectURL(blob);
      
            const a = document.createElement('a');
            a.href = url;
            a.download = 'transaction-details.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
      
            URL.revokeObjectURL(url);
          } catch (error) {
            console.error('Error generating PDF:', error);
          }
        }
      };



    React.useEffect(() => {
        PaymentApis.getTransactionHistory('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setTransactionList(response?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })


    }, []);


    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }

            PaymentApis.getTransactionHistory(value2).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setTransactionList(response?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [transactionList, searchText]);

    


    function formatTime(createdAt?: string): string | null {
        if (!createdAt) {
          return null; // Return null if createdAt is undefined
        }
      
        const date = new Date(createdAt);
        return format(date, 'hh:mm a'); // Formats time as '11:28 am'
      }

      function formatDate(createdAt?: string): string | null {
        if (!createdAt) {
          return null; // Return null if createdAt is undefined
        }
      
        const date = new Date(createdAt);
        return format(date, 'dd-MM-yyyy'); // Formats date as '12-12-2024'
      }


    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
                    <HeaderTitle title="Transaction History" />

                    <div className='mt-8'>

                        <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    
                                    <tr>
                                        <th scope="col" className="px-6 py-3">Transaction&nbsp;Type</th>
                                        <th scope="col" className="px-6 py-3"></th>
                                        <th scope="col" className="px-6 py-3">
                                            Date
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Time
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Transaction&nbsp;ID
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>


                                <tbody className=''>
                                    {
                                        transactionList?.data?.map(
                                            (datas: any, index: any) => (
                                                <tr className="bg-white border-b ">
                                                    <td className="px-6 py-4 flex justify-start gap-3">
                                                        <span>{index + 1}</span>
                                                        {datas?.tnx_naration == 'topup' ? <p><span style={{ fontWeight: "400", fontSize: '12px', color: '#51CF66',  }}> ⬆ {datas?.tnx_naration}</span></p> : <p><span style={{ fontWeight: "400", fontSize: '12px', color: '#FF922B',  }}>⬇ {datas?.tnx_naration}</span></p>}     
                                                    </td>
                                                    <td className="px-6 py-4"></td>
                                                    <td className="px-6 py-4">
                                                  {formatDate(datas?.created_at)}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        
                                                        {formatTime(datas?.created_at)}
                                                    </td>

                                                    <td className="px-6 py-4">
                                                        {datas?.tnx_ref}
                                                    </td>

                                                    <td className="px-6 py-4">
                                                    <span className="">{(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format((datas?.amount_paid) ? (datas?.amount_paid) : 0.0))} </span>
                                                    </td>
                                                                       
                                                    <td className="px-6 py-4">
                                                        {datas?.transaction_status == 'pending' ? <p style={{ fontWeight: "400", fontSize: '13px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', textAlign:"center", padding: '2px 10px' }}>{datas?.transaction_status}</p> : ''}
                                                        {datas?.transaction_status == 'successful' || datas?.transaction_status == 'Successful' ? <p style={{ fontWeight: "400", fontSize: '13px', textAlign:"center", backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</p> : ''}
                                                        {datas?.transaction_status == 'delievered' ? <p style={{ fontWeight: "400", fontSize: '13px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', textAlign:"center", padding: '2px 10px' }}>{datas?.transaction_status}</p> : ''}
                                                        {datas?.transaction_status == 'rejected' ? <p style={{ fontWeight: "400", fontSize: '13px', backgroundColor: '#F5BFBF', color: '#E03130', borderRadius: '10px', textAlign:"center", padding: '2px 10px' }}>{datas?.transaction_status}</p> : ''}

                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <span
                                                            onClick={() => onOpenModal(datas?.id)}
                                                            style={{ fontWeight: "400", fontSize: '12px', color: '#14B266', cursor: "pointer" }}>View&nbsp;Details</span>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }

                                </tbody>


                            </table>
                        </div>

                        {transactionList?.data?.length < 1 ?
                            <div className="d-flex justify-content-center mt-0 py-3" style={{ backgroundColor: '#F4F5FA' }}>
                                <p className="mt-2" style={{ color: '#989DA3', fontWeight: '500' }}>No Record Found!</p>
                            </div>
                            : ''
                        }



                        <div className='m-4 flex justify-end'>
                            {
                                transactionList?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                    (datas: any, index: any) => (
                                        <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#14B266] text-white')}>
                                            {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                        </button>
                                    )
                                )
                            }

                        </div>


                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center styles={{
          modal: {
            borderRadius: '15px', // Add border-radius here
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
          },
        }}>
                <div className='body-font font-poppins rounded-lg'>
                    <div className="md:mx-4 mx-2 ">
                        <div ref={contentRef} className='md:w-[350px] w-[300px] mb-7'
                            // style={{ maxWidth: '600px', margin: '0 auto' }}
                        >
                            <h3 className='text-[#333333]'>Transaction Details</h3>
                            <div className='flex justify-center mt-6'>
                                <img src="/images/afripay-logo.png" />
                            </div>
                            <div>
                                <h3 className='text-center text-black/50 mt-3 capitalize'> {transactionDetails?.tnx_naration}</h3>
                                <h3 className='text-center text-[22px] text-[#333333] font-bold'>€ {transactionDetails?.amount_paid}</h3>
                            </div>
                            <div className='flex justify-center'>
                                <span style={{ fontWeight: "400", fontSize: '12px', color: '#51CF66', }}>{transactionDetails?.transaction_status}</span>
                            </div>
                            <div className='mt-10'>
                               
                                <div className='flex justify-between pt-[3px]'>
                                    <h3 className='text-black/75 text-[14px]'>Total amount paid</h3>
                                    <h2 className='text-black text-[14px] font-[500]'>€{transactionDetails?.amount_paid}</h2>
                                </div>
                                <div className='flex justify-between mt-5'>
                                    <h3 className='text-black/75 text-[14px]'>Time</h3>
                                    <h2 className='text-black text-[14px] font-[500]'>{formatTime(transactionDetails?.created_at)}</h2>
                                </div>
                                <div className='flex justify-between pt-[3px]'>
                                    <h3 className='text-black/75 text-[14px]'>Date</h3>
                                    <h2 className='text-black text-[14px] font-[500]'>{formatDate(transactionDetails?.created_at)}</h2>
                                </div>
                                <div className='flex justify-between pt-[3px] pb-3'>
                                    <h3 className='text-black/75 text-[14px]'>Transaction ID</h3>
                                    <h2 className='text-black text-[14px] font-[500]'>{transactionDetails?.tnx_ref}</h2>
                                </div>
                            </div>


                        </div>
                        <div className='mt-5'>
                            <button
                                onClick={generatePDF2}
                                className='bg-[#DBF1EA] rounded-[12px] text-[#028C63] text-[14px] py-[10px] px-[16px]'>Download</button>
                        </div>
                    </div>
                </div>

            </Modal>

        </>

    )
}

export default Transactions