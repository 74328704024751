import React, { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { History } from "history";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { PaymentApis } from "../../../apis/paymentApis";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const VerifyCreditPremiumLoanPayment = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [subValue, setSubValue] = React.useState(1);
  const [success, setSuccess] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (searchParams.get("status") == "cancelled") {
      // navigate('/new-dashboard');
      toast.error('Payment Failed');
      navigate('/loans-premium-eligibility');
      
    }
  });

  if (searchParams.get("tx_ref")) {
    let data;
    if (searchParams.get("status") == "cancelled") {
      // navigate('/new-dashboard');
      navigate('/loans-premium-eligibility');
    } else {
      PaymentApis.verifyEduPremiumPayment(searchParams.get("tx_ref"))
        .then((response: any) => {
          if (response?.data) {
            // navigate('/wallet');
            if (response?.data?.success === true) {
              // console?.log(response?.data)
              setSuccess(true);
            }
          } else {
            // toast.warn('Invalid Login Credentials');
          }
        })
        .catch(function (error: any) {
          // handle error
          console.log(error.response.data);
        })
        .finally(() => {
          setSuccess(true);
          // toast.error("No Internet Connection");
        });
    }
  }

  return (
    <>

      {success ?

        <Modal open={true} onClose={() => null} center>
          <div className="md:w-[500px]  body-font font-poppins">
            <div className="flex justify-center mt-9">
              <h4 className="text-center text-[30px] font-bold">
                Payment Successful
              </h4>
            </div>
            <div className="flex justify-center mt-2">
              <h4 className="text-center text-[20px] max-w-[450px]">
                Your payment for credit score has been confirmedand is
                currently under review
                <br />
                Congratulation
              </h4>
            </div>
            <div className="flex justify-center">
              <IoIosCheckmarkCircleOutline className="w-[150px] h-[150px] text-[#00B07B]" />
            </div>
            <div className="flex justify-center  pt-7">
              <Link
                to="/loans-premium-access"
                className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
              >
                Proceed
              </Link>
            </div>
          </div>
        </Modal>

        :
        <div className="flex justify-center mt-[50vh]">
          <Oval
            visible={true}
            height="80"
            width="80"
            color="#14B266"
            secondaryColor="#E6F1FC"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      }
    </>
  );
};

export default VerifyCreditPremiumLoanPayment;
